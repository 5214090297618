@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  font-family: Inter;
}

/* we can add some scrollbar styles to use */
@layer utilities {
  @layer responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .no-padding {
      padding: 0;
    }
  }
}
