.defaultScrollbar::-webkit-scrollbar {
  background-color: transparent;
}

.defaultScrollbar::-webkit-scrollbar-thumb {
  @apply bg-customColor_4 transition-all duration-500;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.defaultScrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-customColor_9;
}
.scrollbar_hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollbar_hidden::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.styledScrollbar {
  outline: none;
  height: 100%;
  scrollbar-color: #9ca3af transparent;
  scrollbar-width: thin;
  scroll-margin: 3px;
}
.styledScrollbar::-webkit-scrollbar {
  background-color: transparent;
}
.styledScrollbar::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}
.styledScrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-customColor_3;
}

.fontInter {
  font-family: Inter;
}

.customScrollbar::-webkit-scrollbar {
  background-color: transparent;
  height: 20px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #e5e7eb;
  border: 3px solid transparent;
  background-clip: content-box;
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #cbcdcf;
}

.smallCustomScrollbar::-webkit-scrollbar {
  background-color: transparent;
  height: 10px;
}

.smallCustomScrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #e5e7eb;
  border: 5px solid transparent;
  background-clip: content-box;
}

.smallCustomScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #cbcdcf;
}
