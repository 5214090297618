.wrapper {
  @apply w-full bg-customColor_7 rounded-lg border-y-2 border-x-2 border-customColor_1 flex h-[40px] content-center justify-between;
}
.wrapper:hover {
  @apply border-y-2 border-x-2 border-customColor_3;
}
.wrapper div {
  @apply my-auto;
}
.input {
  @apply w-full bg-customColor_7 text-customColor_4 border-none;
}
.input:focus {
  outline: none;
}
.activated {
  @apply border-x-2 border-y-2 border-customColor_5;
}
.history_list {
  @apply absolute z-50 top-[35px] bg-customColor_17 w-full;
  border: solid silver 1px;
  width: 60%;
}
.history_list_item {
  @apply p-[10px] cursor-pointer hover:bg-customColor_2 active:bg-customColor_10;
}
