.messages {
  @apply w-full h-full overflow-y-scroll;
  scrollbar-color: #9ca3af transparent;
  scrollbar-width: thin;
  scroll-margin: 3px;
}

.messages::-webkit-scrollbar {
  background-color: transparent;
}

.messages::-webkit-scrollbar-thumb {
  @apply bg-customColor_4 transition-all duration-500;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.messages::-webkit-scrollbar-thumb:hover {
  @apply bg-customColor_9;
}

.input {
  @apply w-full h-[40px] p-[5px] rounded-lg flex-1 resize-none absolute bottom-[12px] cursor-auto border border-solid border-gray-300 border;
}
.input::-webkit-scrollbar {
  -webkit-appearance: none;
}
.input::-webkit-scrollbar:vertical {
  width: 15px;
}
.input::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 5px solid white; /* should match background, can't be transparent */
  background-color: rgba(75, 75, 75, 0.756);
  cursor: auto;
}
.input::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.756);
}
.input::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.input :focus {
  border: none;
}
