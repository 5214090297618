th,
td {
  overflow: hidden;
}

th {
  position: sticky;
  top: 0;
}

.resize_handle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  border-left: solid #f3f4f6 4px;
  border-right: solid #f3f4f6 4px;
  cursor: col-resize;
}
