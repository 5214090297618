.content {
  outline: none;
  height: 100%;
  scrollbar-color: #9ca3af transparent;
  scrollbar-width: thin;
  scroll-margin: 3px;
}
.content::-webkit-scrollbar {
  background-color: transparent;
}
.content::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}
.content::-webkit-scrollbar-thumb:hover {
  @apply bg-customColor_3;
}
.content > div[role='rowgroup'] > div[aria-label='row'] {
  @apply border-b-2 border-customColor_1;
}
