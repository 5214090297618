.wrapper {
  @apply w-full h-full bg-customColor_17 px-[25px] overflow-y-auto;
  scrollbar-color: #9ca3af transparent;
  scrollbar-width: thin;
  scroll-margin: 3px;
}
.wrapper::-webkit-scrollbar {
  background-color: transparent;
}
.wrapper::-webkit-scrollbar-thumb {
  background-color: #626569;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.cell_wrapper {
  @apply w-full mt-[20px] h-fit;
}

.cell {
  @apply w-full p-[10px] rounded flex flex-wrap flex-row justify-between;
  border: solid silver 1px;
}
.cell_heading {
  @apply text-2xl mt-[10px] mb-[5px] font-bold;
}

.data_item {
  @apply min-w-[120px] mt-0;
  flex: 0 1 calc(50% - 10px);
}
.row {
  @apply text-customColor_20 text-left;
  border-bottom: solid silver 1px;
}
.button {
  @apply m-[5px] p-[5px] rounded-lg;
  border: solid silver 1px;
}
.active {
  @apply bg-blue-500 text-customColor_17;
}
