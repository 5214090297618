.wrapper::-webkit-scrollbar {
  background-color: transparent;
}
.wrapper::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}
.wrapper::-webkit-scrollbar-thumb:hover {
  @apply bg-customColor_3;
}

.markdown {
  word-wrap: break-word;
  font-size: 0.9375rem;
}

.markdown p,
.markdown blockquote,
.markdown ul,
.markdown ol,
.markdown table,
.markdown pre {
  margin: 0 0 16px 0;
}

.markdown hr {
  border: 1px solid rgba(234, 234, 234, 1);
  border: 1px solid
    var(--component-grid-cell-bottom-border-color, rgba(234, 234, 234, 1));
}
.markdown pre {
  font-size: 0.75rem;
  white-space: pre;
  word-wrap: normal;
  background-color: rgba(0, 0, 0, 0.06);
  background-color: var(--palette-black-alpha-6, rgba(0, 0, 0, 0.06));
  border-radius: 2px;
  padding: 15px;
  overflow-x: auto;
}
.markdown pre code {
  font-size: inherit;
  background-color: transparent;
  padding: 0;
}
.markdown blockquote {
  color: rgba(0, 0, 0, 0.7);
  color: var(--palette-black-alpha-70, rgba(0, 0, 0, 0.7));
  border-left: 2px solid rgba(234, 234, 234, 1);
  border-left: 2px solid
    var(--component-grid-cell-bottom-border-color, rgba(234, 234, 234, 1));
  padding-left: 16px;
}
.markdown blockquote > *:first-child {
  margin-top: 0;
}
.markdown blockquote > *:last-child {
  margin-bottom: 0;
}
.markdown blockquote > p,
.markdown blockquote ol,
.markdown blockquote ul {
  margin: 16px 0;
}
.markdown img {
  max-width: 100%;
}
.markdown ul,
.markdown ol {
  padding-left: 32px;
  list-style: disc;
}
.markdown ul ul,
.markdown ol ol {
  padding-left: 32px;
  list-style: circle;
}
.markdown ul ul,
.markdown ul ol,
.markdown ol ul,
.markdown ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown ul ul ul,
.markdown ol ol ol {
  padding-left: 32px;
  list-style: square;
}
.markdown li {
  list-style: inherit;
}
.markdown li + li {
  margin-top: 4px;
}
