.ck-balloon-panel,
.ck-toolbar_floating {
  height: 26px !important;
}
.ck-toolbar__items {
  height: 26px;
  max-height: 26px;
  display: flex;
  flex-direction: row;
}
.ck.ck-button {
  height: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
}
.ck-toolbar__items > button > span,
.ck-label,
.ck-toolbar__items > div > button > span,
.ck-powered-by,
.ck-dropdown__arrow {
  display: none;
}
.ck-editor__editable {
  height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  line-height: 1.6;
  word-break: break-word;
  @apply font-normal leading-normal text-customColor_4;
}

.editor-container_inline-editor .editor-container__editor {
  min-width: 795px;
  max-width: 795px;
}
