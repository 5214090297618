.tabs {
  @apply gap-2 z-[2] relative;
  & > button {
    @apply px-0;
  }
  & > div > div {
    @apply p-0;
  }
  & > div {
    @apply mx-8;
    height: 100%;
    display: flex;
    gap: 0;
  }
  & > div > div[role='tablist'] {
    flex: 0 0 auto;
  }
  & > div > div[role='tablist'] > button {
    @apply border-b cursor-pointer rounded-t-md;
    outline: none !important;
  }
  & > div > div[role='tablist'] > button[aria-selected='true'] {
    border-bottom: 1px solid #0170df;
  }
  & > div > div:nth-child(2) {
    flex: 1 1 100%;
  }
  & > div > div:nth-child(2) > div {
    height: 100%;
    padding: 0;
  }
  height: 100%;
}
.hideScrollbar::-webkit-scrollbar {
  display: none !important;
}
.container {
  scrollbar-color: #9ca3af transparent;
  scrollbar-width: thin;
  scroll-margin: 3px;
}

.container::-webkit-scrollbar {
  background-color: transparent;
}

.container::-webkit-scrollbar-thumb {
  @apply bg-customColor_4 transition-all duration-500;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.container::-webkit-scrollbar-thumb:hover {
  @apply bg-customColor_9;
}
